import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Section } from '../../../model/section.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from './validation.service';

@Component({
  selector: 'hpm-section-edit',
  templateUrl: './section-edit.component.html',
  styleUrl: './section-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() section!: Section;
  form: FormGroup;

  headingToggle = false;
  introductionTextToggle = false;
  conditionExpressionToggle = false;
  instructionsToggle = false;

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      condition: [
        '',
        null,
        [this.validationService.validateCondition.bind(this.validationService)],
      ],
      heading: [
        '',
        null,
        [this.validationService.validateText.bind(this.validationService)],
      ],
      headingLevel: 1,
      introductionText: [
        '',
        null,
        [this.validationService.validateText.bind(this.validationService)],
      ],
    });
  }

  ngOnInit(): void {
    this.headingToggle = !!this.section.heading;
    this.introductionTextToggle = !!this.section.introductionText;
    this.conditionExpressionToggle = !!this.section.conditionExpression;
    this.instructionsToggle = !!this.section.instructions?.length;

    this.form.patchValue({
      condition: this.section.conditionExpression,
      heading: this.section.heading,
      headingLevel: this.section.headingLevel,
      introductionText: this.section.introductionText,
    });

    this.form.valueChanges.subscribe((changedSection) => {
      this.section.conditionExpression = changedSection.condition?.trim();
      this.section.heading = changedSection.heading;
      this.section.headingLevel = changedSection.headingLevel
        ? changedSection.headingLevel
        : 1;
      this.section.introductionText = changedSection.introductionText;
      this.section.conditionValid = this.form.controls['condition'].valid;
      this.section.headingValid = this.form.controls['heading'].valid;
      this.section.introductionTextValid =
        this.form.controls['introductionText'].valid;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.form.updateValueAndValidity({ emitEvent: false });
    this.form.markAllAsTouched();
    setTimeout(() => {
      // validator needs some time
      this.cdr.detectChanges();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.form.updateValueAndValidity();
  }

  changedHeadingToggle(checked: boolean): void {
    this.headingToggle = checked;
    if (!this.headingToggle) {
      this.form.controls['heading'].setValue('');
      this.form.controls['headingLevel'].setValue(null);
    } else {
      this.form.controls['headingLevel'].setValue(1);
    }
  }

  changedConditionExpressionToggle(checked: boolean): void {
    this.conditionExpressionToggle = checked;
    if (!this.conditionExpressionToggle) {
      this.form.controls['condition'].setValue('');
    }
  }

  changedIntroductionTextToggle(checked: boolean): void {
    this.introductionTextToggle = checked;
    if (!this.introductionTextToggle) {
      this.form.controls['introductionText'].setValue('');
    }
  }

  changedInstructionsToggle(checked: boolean): void {
    this.instructionsToggle = checked;
    if (!this.instructionsToggle) {
      this.section.instructions = [];
    }
  }
}
